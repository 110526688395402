import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import * as R from 'ramda'
import { Box } from '@chakra-ui/react'
import './style.sass'
import { uuid } from 'uuidv4'
import { FormattedMessage } from 'react-intl'
import { DeleteIcon } from '@chakra-ui/icons'
import useWindowSize from '../sizeWindow/windowSizeHook'

type FileFormProps = {
  formats?: string

  isPreview?: boolean
  isMultiple?: boolean
  isDeleteFile?: boolean

  handleChange: (data: any) => void
}

function FileForm({
  formats,
  isPreview,
  isMultiple,
  isDeleteFile,

  handleChange,
}: FileFormProps) {
  const [id, setId] = useState<string>()
  const [image, setImage] = useState<any>()
  const [fileName, setFileName] = useState<string>('')

  const inputRef = useRef<any>()

  const size = useWindowSize()

  const onChange = (e: any): void => {
    if (R.isNil(e)) {
      const el = document.getElementById(`upload-container__${id}`) as HTMLFormElement
      if (el) {
        if (el.children[0]?.tagName === 'IMG') el.removeChild(el.children[0])
        setFileName('')
      }
      setImage(undefined)
      handleChange(undefined)
    } else {
      const reader = new FileReader();
      reader.onloadend = function() {
        if (!R.isNil(reader.result)) {
          setImage(reader.result)
          const el = document.getElementById(`upload-container__${id}`) as HTMLFormElement
          if (el) {
            if (isPreview && !isMultiple) {
              if (el.children[0]?.tagName === 'IMG') el.removeChild(el.children[0])
              const img = document.createElement('img')
              img.src = String(reader.result)
              img.style.maxHeight = `${el.clientHeight - 20}px`
              img.style.maxWidth = `${el.clientWidth - 20}px`
              img.style.height = 'auto'
              img.style.width = 'auto'
              el.prepend(img)
            } else if (isMultiple) {
              setFileName(R.join(', ', R.map(({ name }: any) => name, e.target.files)))
            } else {
              setFileName(e.target.files[0].name)
            }
          }
        }
      };

      reader.readAsDataURL(e.target.files[0])
      reader.onerror = function() {
        // eslint-disable-next-line no-console
        console.log(reader.error);
      };

      handleChange(e.target.files)
    }
  }

  const handleClick = (): void => {
    if (inputRef.current) {
      const el = inputRef.current as HTMLInputElement
      el.click()
    }
  }

  function handleDrop(e: any) {
    onChange({ target: { files: e.dataTransfer.files } })
  }

  function preventDefaults (e: any) {
    e.preventDefault()
    e.stopPropagation()
  }

  useEffect(() => {
    setId(uuid())
  }, [])

  useEffect(() => {
    const dropArea = document.getElementById(`upload-container__${id}`)
    function highlight() {
      if (dropArea) {
        dropArea.classList.add('highlight')
      }
    }
    function unhighlight() {
      if (dropArea) {
        dropArea.classList.remove('highlight')
      }
    }
    if (dropArea) {
      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName: any) => {
        dropArea.addEventListener(eventName, preventDefaults, false)
      });
      ['dragenter', 'dragover'].forEach(eventName => {
        dropArea.addEventListener(eventName, highlight, false)
      });
      ['dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, unhighlight, false)
      })
      dropArea.addEventListener('drop', handleDrop)
    }
  }, [id])

  return (
    <Box
      id={`upload-container__${id}`}
      className="upload-container"
      style={{
        cursor: image ? 'pointer' : 'default',
      }}
      onClick={handleClick}
    >
      <div>
        <input
          type="file"
          ref={inputRef}
          className="file"
          accept={formats}
          onChange={onChange}
          multiple={isMultiple}
          id={`file-input__${id}`}
        />
        {!image && (
          <>
            <FormattedMessage id="application.form.file_desc.first">
              { txt => (
                <>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor={`file-input__${id}`} onClick={(e:SyntheticEvent) => { e.stopPropagation() }}>{String(txt[0])}</label>
                </>
              )}
            </FormattedMessage>
            {size.typeDisplay === 'Desktop' && (
              <FormattedMessage id="application.form.file_desc.second">
                { txt => (
                  <span>{String(txt[0])}</span>
              )}
              </FormattedMessage>
            )}
          </>
        )}
        {fileName && (
          <div>
            {fileName}
          </div>
        )}
      </div>
      {formats && <div className="file-formats"><FormattedMessage id="application.form.file_format" />{formats}</div>}
      {isDeleteFile && image && (
        <DeleteIcon
          className="delete-icon"
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            onChange(undefined)
          }}
        />
)}
    </Box>
  )
}

FileForm.defaultProps = {
  isPreview: false,
  isMultiple: false,
  isDeleteFile: false,
  formats: '',
}

export default FileForm
