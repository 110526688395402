import { takeEvery, call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { SagaIterator } from 'redux-saga';
import * as R from 'ramda'
import { push } from 'redux-first-history';
import {
  applicationInfoA,
  changeVisibleDrawerA,
  createApplicationA,
} from './actions';

import ApplicationsAPI from '../../services/API/Application'

/** Создание заявки */
function* createApplicationSaga(action: ActionType<typeof createApplicationA.request>): SagaIterator {
  try {
    const formData = new FormData();
    R.forEachObjIndexed((value: any, key: any) => {
      formData.append(key, value)
    }, action.payload)
    const { data } = yield call([ApplicationsAPI, ApplicationsAPI.createApplication], formData)
    yield put(createApplicationA.success(data))
    yield put(changeVisibleDrawerA(false))
    localStorage.setItem('application', '1')
    yield put(push(`/application/search/${data}`))
  } catch (error) {
    yield put(createApplicationA.failure(error as Error))
  }
}

/** Заявка */
function* applicationInfoSaga(action: ActionType<typeof applicationInfoA.request>): SagaIterator {
  try {
    const { data } = yield call([ApplicationsAPI, ApplicationsAPI.getApplication], action.payload.value)
    yield put(applicationInfoA.success(data))
  } catch (error) {
    clearInterval(action.payload.runner)
    yield put(applicationInfoA.failure(error as Error))
  }
}

export default function* (): SagaIterator {
  yield takeEvery(applicationInfoA.request, applicationInfoSaga)
  yield takeEvery(createApplicationA.request, createApplicationSaga)
}
